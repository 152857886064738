$titleFont: 'Exo', sans-serif;
$contentFont: 'Roboto', sans-serif;

$mainBgColor: #f2f2f2;
$secondaryColor: #009245;
$inputBorderColor: #dbdbdb;
$inputColour: hsl(0, 0%, 21%);
$mainGreen: #00A053;

$headerHeight: 50px;
$brandListHeight: 50px;

$smallUp: '(min-width: 320px)';
$mediumUp: '(min-width: 640px)';
$xMediumUp: '(min-width: 768px)';
$largeUp: '(min-width: 1024px)';
$xLargeUp: '(min-width: 1224px)';
$xxLargeUp: '(min-width: 1440px)';
$xxxLargeUp: '(min-width: 1680px)';
$xxxxLargeUp: '(min-width: 1915px)';

@mixin bold-font {
    @if & {
        .app-container & {
            font-family: 'axi-bold';
        }
    } @else {
        .app-container {
            font-family: 'axi-bold';
        }
    }

    @if & {
        .app-container.lang-ru & {
            font-family: 'gilroy-bold';
        }
    } @else {
        .app-container.lang-ru {
            font-family: 'gilroy-bold';
        }
    }
}

@mixin regular-font {
    @if & {
        .app-container & {
            font-family: 'axi-book';
        }
    } @else {
        .app-container {
            font-family: 'axi-book';
        }
    }

    @if & {
        .app-container.lang-ru & {
            font-family: 'gilroy-regular';
        }
    } @else {
        .app-container.lang-ru {
            font-family: 'gilroy-regular';
        }
    }
}

@mixin brave-font {
    @if & {
        .app-container & {
            font-family: 'brave';
        }
    } @else {
        .app-container {
            font-family: 'brave';
        }
    }

    @if & {
        .app-container.lang-ru & {
            font-family: 'trazos';
        }
    } @else {
        .app-container.lang-ru {
            font-family: 'trazos';
        }
    }
}

//MIN-WIDTH

@mixin for-smallUp-only {
    @media (min-width: 320px) {
        @content;
    }
}

@mixin for-400Up-only {
    @media (min-width: 400px) {
        @content;
    }
}

@mixin for-mediumUp-only {
    @media (min-width: 640px) {
        @content;
    }
}

@mixin for-phone-only {
    @media (max-width: 768px) {
        @content;
    }
}

@mixin for-tablet-and-phone {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 769px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 1024px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-large-desktop-up {
    @media (min-width: 1440px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1680px) {
        @content;
    }
}
