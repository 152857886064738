@use 'variables';

.header {
    height: 80px;
    left: 0;
    position: relative;
    z-index: 100;
    top: 0;
    width: 100%;
    @include variables.for-tablet-portrait-up {
        height: 90px;
    }
    .top {
        align-items: center;
        background: #fff;
        display: flex;
        height: 50px;
        position: relative;
        width: 100%;
        padding: 0px;
        @include variables.for-tablet-landscape-up {
            height: 60px;
            padding: 0 30px;
        }
        .logo {
            background: url('../../../images/rademar_logo_touch.svg') no-repeat center;
            height: 30px;
            margin: 0 15px 0 15px;
            width: 30px;
            @include variables.for-tablet-landscape-up {
                margin: 0;
            }
            @include variables.for-desktop-up {
                background: url('../../../images/rademar_green.svg') no-repeat center;
                height: 30px;
                margin: 0;
                width: 150px;
            }
        }
        .header-favourite {
            display: none;
            align-items: center;
            border-radius: 4px;
            height: 40px;
            justify-content: center;
            margin: 0 20px 0 10px;
            min-width: 44px;
            @include variables.for-tablet-landscape-up {
                display: flex;
            }
            &.black {
                background: #000000;
            }
            .favourite-icon {
                height: 20px;
                width: 24px;
            }
        }
    }
}