@use 'variables';

.component-product {
    color: #000000;
    position: relative;
    text-decoration: none;
    padding: 0px 10px;
    @include variables.for-mediumUp-only {
        padding: 0px 5px;
    }
    &__image {
        position: relative;
        .wrapper {
            position: relative;
            height: 0;
            /* Formula is: (height / width * 100%) */
            padding-top: calc(360 / 360 * 100%);
            background-color: #f9f9f9;
            border-radius: 4px;
            .wrapper-img {
                position: absolute;
                top: 0;
                left: 0;
                max-width: 100%;
                height: auto;
            }
        }
        img {
            border-radius: 4px 4px 4px 4px;
        }
    }
    &__info {
        box-sizing: border-box;
        padding: 8px 5px 0px 5px;
        display: flex;
        flex-wrap: wrap;
        @include variables.for-mediumUp-only {
            padding: 15px 5px 10px 5px;
        }
        &__left {
            flex: 0 0 100%;
            padding-right: 5px;
            &__category {
                color: #959595;
                font-size: 12px;
                text-transform: none;
                @include variables.for-mediumUp-only {
                    font-size: 13px;
                }
            }
        }
        &__right {
            margin-top: 5px;
        }
    }
}
