@use 'variables';

.component-register {
    padding-top: 40px;
    padding-bottom: 40px;
    .info {
        display: flex;
        font-size: 0.875rem;
        flex: 0 0 100%;
        line-height: 1.75;
        margin: 15px 0px 20px 0px;
        @include variables.regular-font;
    }
    button {
        display: block;
        cursor: pointer;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        font-size: 0.875rem;
        padding: 14px 20px;
        margin-top: 20px;
        @include variables.bold-font;
        &:hover {
            background: #00A053;
            border: 1px solid #00A053;
            color: #ffffff;
        }
    }

    .form {
        &__items {
            display: flex;
            flex-wrap: wrap;
        }
        .register-email {
            width: 100%;
            flex: 0 0 100%;
            margin: 0px;
            padding: 0px 0px 20px 0px;
        }

        .registerPassword {
            padding-left: 0px;
            padding-right: 0px;
            padding-bottom: 20px;
            margin: 0;
            @include variables.for-tablet-landscape-up {
                padding-right: 10px;
                padding-bottom: 10px;
            }
        }

        .registerPasswordConfirmation {
            padding-right: 0px;
            padding-left: 0px;
            padding-bottom: 20px;
            margin: 0;
            @include variables.for-tablet-landscape-up {
                padding-left: 10px;
                padding-bottom: 10px;
            }
        }

        .terms {
            display: flex;
            flex: 0 0 100%;
            font-size: 14px;
            margin-bottom: 20px;
            margin-top: 20px;
            a {
                padding-left: 5px;
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }
}
