@use 'variables';

.component-reset-password {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    &__title {
        margin-top: 20px;
    }

    &__subtitle {
        line-height: 27px;
        font-size: 16px;
        margin: 10px 0px;
        @include variables.regular-font;
    }
    &__input {
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        label {
            margin-bottom: 15px;
            font-size: 14px;
            @include variables.bold-font;
        }
        input {
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            padding-left: 15px;
            margin-bottom: 20px;
            height: 50px;
        }
    }

    .info {
        display: flex;
        flex: 0 0 100%;
        align-items: center;
        padding: 20px 20px;
        margin-top: 30px;
        padding: 20px 15px;
        border: 1px solid #f3fff3;
        border-radius: 4px;
        background: #f3fff3;

        p {
            line-height: 27px;
            padding: 0px 20px;
            font-size: 14px;
            margin: 0;
            @include variables.regular-font;
        }

        img {
            width: 30px;
            height: 30px;
        }
    }

    &__button {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        p {
            cursor: pointer;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e2e2e2;
            font-size: 0.875rem;
            padding: 8px 20px;
            line-height: 27px;
            @include variables.bold-font;
            &:hover {
                background: #00A053;
                border: 1px solid #00A053;
                color: #ffffff;
            }
        }
        span {
            margin-left: auto;
            text-decoration: underline;
            cursor: pointer;
        }
    }
}
