@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    .h1 {
        @apply font-axi-bold text-6xl leading-[72px] text-black
    }
    .h2 {
        @apply font-axi-bold text-4xl leading-[43px] text-black
    }
    .h3 {
        @apply font-axi-bold text-2xl leading-[38px] text-black
    }
    .h4 {
        @apply font-axi-bold text-xl leading-8 text-black
    }
    .paragraph {
        @apply  text-base leading-6 text-black
    }
    .label {
        @apply  text-sm leading-4 text-black
    }
    .label-small {
        @apply  text-xs leading-[15px] text-black
    }
}

.range-slider {
    background-color: #E2E2E2;
}
.range-slider__thumb {
    background-color: white !important;
    border: 6px solid black ;
}
.range-slider__range {
    background-color: black !important;
}

/* Necessary styles for react-responsive carousel */

.carousel-root, .carousel-slider, .slider-wrapper, .slider {
    @apply h-full
}

.carousel-status {
    @apply hidden;
}

.carousel.carousel-slider .control-arrow {
    @apply p-6
}

.thumbs-wrapper {
    @apply absolute;
}

.carousel {
    .control-arrow {
        border-radius: 0.3rem;
        display: flex !important;
        height: 5rem; 
        justify-content: center;
        z-index: 10;
        
        &.control-next {
            right: 0;
            margin-top: 33%;
            width: 2.5rem;
            
            &:before {
                content: "";
                margin-top: 0.4rem;
                margin-right: 0.8rem;
                position: absolute;
                border: solid white;
                border-width: 0 0.19rem 0.19rem 0;
                display: inline-block;
                padding: 0.4rem;
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }
        
        &.control-prev {
            left: 0;
            margin-top: 33%;
            width: 2.5rem;
            
            &:before {
                content: "";
                margin-top: 0.4rem;
                margin-left: 0.8rem;
                position: absolute;
                border: solid white;
                border-width: 0 0.19rem 0.19rem 0;
                display: inline-block;
                padding: 0.4rem;
                transform: rotate(-225deg);
                -webkit-transform: rotate(-225deg);
            }
        }
    }
    .control-dots
        .dot {
            height: 0.2rem;
            width: 1.875rem;
            border-radius: 0.13rem;
            
    }
}

.footerBrands {
    &__slider {
        .slick-list {
            margin: 0px 15px;
        }

        .slick-next {
            right: 0px;
            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: -5px;
                width: 30px;
                height: 30px;
            }
            &::after {
                content: '';
                position: absolute;
                top: 5px;
                left: 9px;
                border: solid white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                -ms-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform: rotate(-45deg);
            }
        }

        .slick-prev {
            left: -2px;
            &::before {
                content: '';
                position: absolute;
                right: 0;
                top: -5px;
                width: 30px;
                height: 30px;
            }
            &::after {
                content: '';
                position: absolute;
                top: 5px;
                right: 9px;
                border: solid white;
                border-width: 0 2px 2px 0;
                display: inline-block;
                padding: 4px;
                transform: rotate(135deg);
                -webkit-transform: rotate(135deg);
            }
        }
    }
}

.react-tel-input {
    .country-list {
        overflow-y: hidden !important;
    }
}

/* Necessary styles for SwiperJS carousel */

:root {
    --swiper-theme-color: #fff;
    --swiper-navigation-size: 1.3rem;
}

.swiper-pagination-bullet {
    height: 0.25rem;
    width: 2.2rem;
    border-radius: 0.13rem; 
    --swiper-theme-color: #fff;
}


@media (max-width: 768px) {
    .swiper-pagination {
        padding-top: 1rem;
        position: relative;
    }
}

@media (max-width: 768px) {
    .swiper-pagination-bullet{
        --swiper-theme-color: #000;
    }
}

.swiper-button-next,
.swiper-button-prev {
    padding: 30px 16px;
    top: 46%;
    opacity: 60%;
    background-color: #d5d5d5;
    border-radius: 4px;
    color: #000;
}

@media (max-width: 768px) {
    .swiper-button-next,
    .swiper-button-prev {
        top: 34%;
        // opacity: 0;
    }
}