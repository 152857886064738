@use 'variables';

.component-quantityButton {
    display: flex;
    justify-content: center;

    &__container {
        display: flex;
        border: 1px solid #e2e2e2;
        border-radius: 4px;
        height: 35px;
        @include variables.bold-font;
    }

    &__add {
        padding: 10px;
        border-left: 1px solid #e2e2e2;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        transition: all 0.1s;
        cursor: pointer;
        @include variables.for-tablet-landscape-up {
            &:hover {
                background-color: #000;
                color: #fff;
                transition: all 0.1s;
                border-left: 1px solid #000;
            }
        }
    }

    &__title {
        padding: 10px 15px;
    }

    &__remove {
        padding: 10px;
        border-right: 1px solid #e2e2e2;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        transition: all 0.1s;
        cursor: pointer;
        @include variables.for-tablet-landscape-up {
            &:hover {
                background-color: #000;
                color: #fff;
                transition: all 0.1s;
                border-right: 1px solid #000;
            }
        }
    }
}
