@use 'variables';
.component__social {
    display: flex;
    flex-wrap: wrap;
    margin: 45px -12px 30px -12px;
    a {
        display: flex;
        flex: 1 1 100%;
        justify-content: center;
        align-items: center;
        background-color: #f7f7f7;
        font-size: 18px;
        min-height: 80px;
        border-radius: 10px;
        margin: 10px 12px;
        transition: all 0.1s;
        cursor: pointer;
        @include variables.bold-font;
        &:hover {
            background-color: #000;
            transition: all 0.1s;
            color: #fff;
        }
        @include variables.for-tablet-landscape-up {
            flex: 1 1 30.33%;
            min-height: 180px;
            font-size: 30px;
        }
    }
}
