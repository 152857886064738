@use 'variables';

.alert {
    display: flex;
    position: relative;
    flex: 0 0 100%;
    align-items: center;
    padding: 20px 20px 20px 35px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;

    &__border {
        position: absolute;
        left: -1px;
        top: -1px;
        bottom: -1px;
        width: 20px;
        border-radius: 4px 0px 0px 4px;
        background-color: #ff5350;
    }

    &__image {
        img {
            width: 30px;
            height: 30px;
        }
    }

    &__content {
        &__item {
            line-height: 24px;
            margin: 0 !important;
            padding: 0px 15px;
            font-size: 14px;
            margin: 0;
            color: #000;
            @include variables.bold-font;
        }
    }

    &.success {
        .alert__border {
            background-color: #00A053;
        }
    }

}
