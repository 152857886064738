@use 'variables';
.component-newsLetter {
    h2 {
        cursor: pointer;
    }
}

.newsLetter {
    position: fixed;
    outline: none;

    &__overlay {
        position: fixed;
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.6);
        z-index: 99999;
        cursor: pointer;
    }

    &__content {
        max-width: 510px;
        border-radius: 10px;
        margin: 15px;
        background-color: #fff;
    }

    &__banner {
        position: relative;
        &::before {
            content: '';
            inset: 0;
            position: absolute;
            background-color: rgba(0, 0, 0, 0.2);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
        img {
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
        }
    }

    &__exit {
        width: 40px;
        height: 40px;
        background: #000;
        position: absolute;
        top: 0;
        right: 0;
        margin: 15px;
        border-radius: 4px;
        cursor: pointer;
        &:before,
        &:after {
            position: absolute;
            left: 48%;
            top: 26%;
            content: ' ';
            height: 20px;
            width: 2px;
            background-color: #fff;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__info {
        box-sizing: border-box;
        padding: 40px;
        position: relative;

        h3 {
            font-size: 18px;
            line-height: 27px;
            @include variables.for-desktop-up {
                font-size: 26px;
                line-height: 36px;
            }
        }

        p {
            line-height: 27px;
            font-size: 16px;
            margin: 25px 0px;
            @include variables.regular-font;
            a {
                text-decoration: underline;
                padding-left: 5px;
            }
        }

        strong {
            @include variables.bold-font;
        }

        form {
            display: flex;
            flex-direction: column;
            label {
                font-size: 14px;
                @include variables.bold-font;
            }
            input {
                width: 100%;
                min-height: 50px;
                padding: 10px 15px 10px 15px;
                margin-top: 15px;
                border: 1px solid #e2e2e2;
                border-radius: 4px;
            }
        }

        &__button {
            display: flex;
            button {
                cursor: pointer;
                background: #ffffff;
                border-radius: 4px;
                margin-top: 10px;
                border: 1px solid #e2e2e2;
                padding: 19px 20px;
                font-size: 16px;
                @include variables.bold-font;
                &:hover {
                    background-color: #00A053;
                    border: 1px solid #00A053;
                    color: #fff;
                }
            }
        }

        &__confirm {
            position: absolute;
            inset: 0;
            background-color: #fff;
            display: flex;
            justify-content: center;
            align-items: center;
            opacity: 0;
            pointer-events: none;
            &--visible {
                position: absolute;
                inset: 0;
                background-color: #fff;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                transition: opacity 0.5s;
                opacity: 1;
                pointer-events: none;
                padding: 40px;
                p {
                    text-align: center;
                    font-size: 16px;
                    line-height: 27px;
                    @include variables.bold-font;
                }
                svg {
                    fill: #00A053;
                    max-width: 60px;
                    max-height: 60px;
                }
            }
        }
    }
}
