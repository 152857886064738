@use 'variables';

.component-miniCart {
    position: relative;

    &-button {
        align-items: center;
        cursor: pointer;
        display: flex;
        justify-content: space-evenly;
        padding: 12px 15px;
        max-height: 60px;
        min-width: 60px;
        min-height: 60px;
        position: relative;
        background-color: #fff;

        @include variables.for-tablet-landscape-up {
            border-radius: 4px;
            padding: 12px 13px;
            height: 40px;
            max-width: 50px;
            max-height: 40px;
            min-height: 40px;
            border: 1px solid #e2e2e2;
        }
        &.has-items {
            background: #00A053;
            border: 1px solid #00A053;

            svg {
                fill: #fff;
            }

            .component-miniCart-nrOfItems {
                color: #fff;
            }
        }
        svg {
            fill: #000;
            width: 24px;
            height: 26px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    &-nrOfItems {
        color: #000;
        display: block;
        font-size: 0.75rem;
        height: 20px;
        line-height: 22px;
        padding-top: 2px;
        margin-left: -1px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include variables.bold-font;
    }

    &-container {
        position: fixed;
        display: flex;
        flex-direction: column;
        z-index: 9999;
        top: 60px;
        bottom: 0;
        left: 100%;
        width: 100%;
        border-radius: 0px;
        padding: 20px;
        overflow: scroll;
        background-color: #fff;
        transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        &.open {
            transform: translateX(-100%);
            -webkit-transform: translateX(-100%);
            transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
        }
    }

    &-header {
        display: flex;
        align-items: center;
    }

    &-title {
        flex: 1;
        font-size: 2.2rem;
        line-height: 3.2rem;
    }

    &-close {
        cursor: pointer;
    }

    &-footer {
        display: flex;
        flex-direction: column;
        margin-top: 35px;

        &__inner {
            padding: 0px 10px 0px 10px;

            border-radius: 4px;
            p {
                display: flex;
                margin: 0px 0px 20px 0px;
                &:last-child {
                    padding-top: 20px;
                    margin-top: 10px;
                    border-top: 1px solid #e2e2e2;
                    font-size: 1.8rem;
                    @include variables.bold-font;
                }
            }

            span {
                margin-left: auto;
            }
        }
    }

    &-pay {
        background-color: #00A053;
        padding: 17px 16px;
        border-radius: 4px;
        display: flex;
        justify-content: center;
        color: #fff;
        margin: 15px 0px 20px 0px;
        transition: all 0.1s;
        cursor: pointer;
        @include variables.bold-font;
    }

    &-overlay {
        display: none;
        position: fixed;
        inset: 0;
        background-color: rgba(0, 0, 0, 0.4);
        z-index: 9998;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s ease;
        &.open {
            opacity: 1;
            transition: all 0.4s ease;
            pointer-events: visible;
            cursor: pointer;
        }
    }

    &-product {
        &__content {
            display: flex;
            flex-direction: column;
            padding-right: 45px;
            flex: 1;
            .price {
                margin-top: 20px;
                &__label {
                    display: block;
                }
            }
        }

        &__info {
            display: flex;
            flex: 1;
        }

        &__productTitle {
            font-size: 1.4rem !important;
            line-height: 24px;
            @include variables.for-tablet-landscape-up {
                font-size: 1.5rem;
                line-height: 25px;
            }
        }

        &__brandTitle {
            font-size: 0.75rem;
            line-height: 22px;
            color: #959595;
            @include variables.bold-font;
            @include variables.for-tablet-landscape-up {
                font-size: 0.875rem;
                line-height: 24px;
            }
        }

        &__sizes {
            display: flex;
            margin-top: 10px;
        }

        &__size {
            display: flex;
            align-items: center;
            text-align: center;
            font-size: 0.75rem;
            height: 35px;
            padding: 5px 10px;
            margin-right: 10px;
            border: 1px solid #e2e2e2;
            border-radius: 4px;
            @include variables.bold-font;
        }

        &__image {
            margin-right: 15px;
            img {
                border-radius: 4px;
            }
        }

        &__remove {
            width: 25px;
            height: 25px;
            margin-left: auto;
            background-color: #000;
            border-radius: 4px;
            position: absolute;
            right: 8px;
            cursor: pointer;
            &::before {
                position: absolute;
                content: '';
                width: 2px;
                border-radius: 1px;
                background-color: #fff;
                height: 14px;
                transform: rotate(45deg);
                left: 12px;
                top: 5px;
            }
            &::after {
                position: absolute;
                content: '';
                width: 2px;
                border-radius: 1px;
                background-color: #fff;
                height: 14px;
                transform: rotate(-45deg);
                left: 12px;
                top: 5px;
            }
        }
    }

    &-empty {
        display: flex;
        justify-content: center;
        padding: 40px 0px;
    }

    @include variables.for-tablet-landscape-up {
        &-container {
            width: 490px;
            top: 0px;
            padding: 40px;
        }

        &-overlay {
            display: block;
        }

        &-products {
            flex-wrap: nowrap;
            flex-direction: column;
        }
    }
}
