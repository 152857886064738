@use 'variables';

.component-blogItem {
    padding: 10px;
    position: relative;
    @include variables.for-tablet-landscape-up {
        flex: 0 0 50%;
    }

    //simulates as placeholer
    &::before {
        position: absolute;
        content: '';
        top: 10px;
        left: 10px;
        bottom: 10px;
        right: 10px;
        background-color: #e2e2e2;
        border-radius: 10px;
    }

    &__image {
        position: relative;
        overflow: hidden;
        -webkit-mask-image: -webkit-radial-gradient(white, black);
        border-radius: 10px;
        img {
            transition: all 0.3s;
        }
        &::before {
            position: absolute;
            content: '';
            bottom: 0;
            left: 0;
            right: 0;
            top: 0;
            display: block;
            background-color: #000;
            opacity: 0.15;
            border-radius: 10px;
            z-index: 1;
        }
    }

    &__new {
        position: absolute;
        top: 35px;
        left: 35px;
        color: #fff;
        font-size: 13px;
        @include variables.bold-font;
        @include variables.for-mediumUp-only {
            font-size: 16px;
        }
    }

    &__content {
        position: absolute;
        top: 10px;
        bottom: 10px;
        left: 10px;
        right: 10px;
        border-radius: 10px;
        box-sizing: border-box;
        padding: 20px 20px 10px 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        @include variables.for-mediumUp-only {
            padding: 40px;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        padding-bottom: 10px;
        font-size: 13px;
        @include variables.for-mediumUp-only {
            font-size: 14px;
        }
    }

    &__date {
        color: #fff;
    }

    &__categories {
        color: #fff;
        display: flex;
        overflow: hidden;
        span {
            padding-left: 15px;
            margin-left: 5px;
            color: #fff;
            position: relative;
            @include variables.regular-font;
            &:first-child {
                margin-left: 0px;
                &::before {
                    position: absolute;
                    content: '';
                    width: 10px;
                    height: 10px;
                    left: 0px;
                }
            }
            &::before {
                position: absolute;
                content: '/';
                width: 10px;
                height: 10px;
                left: 0px;
            }
        }
    }

    &__title {
        color: #fff;
        font-size: 16px;
        line-height: 26px;
        @include variables.for-mediumUp-only {
            line-height: 32px;
            font-size: 22px;
        }
    }
    @include variables.for-tablet-landscape-up {
        &:hover {
            img {
                transform: scale(1.03);
                transition: all 0.3s;
            }
        }
    }
}
