@use 'variables';

.component-homeBlock {
    margin: 30px 0px 40px 0px;
    @include variables.for-tablet-landscape-up {
        margin: 40px 0px 30px 0px;
    }

    &-options {
        display: flex;
        overflow-x: scroll;
        scroll-snap-type: x mandatory;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            width: 0px;
            background: transparent;
        }
        @include variables.for-mediumUp-only {
            overflow-x: visible;
        }
    }

    &-option,
    &-option--selected {
        padding-right: 40px;
        position: relative;
        color: #959595;
        scroll-snap-align: center;
        cursor: pointer;
        &::after {
            position: absolute;
            content: '/';
            top: 10px;
            right: 10px;
            font-size: 30px;
            color: #959595;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
        @include variables.for-tablet-landscape-up {
            h2 {
                font-size: 2.5rem;
            }
        }
    }

    &-option--selected {
        color: #000;
    }

    &-content {
        display: flex;
        flex-direction: column;
        margin: 0px -15px;
        @include variables.for-desktop-up {
            flex-direction: row;
            flex-wrap: wrap;
        }
        .products-block {
            margin: 35px 15px 0px 15px;
            position: relative;
            width: auto;
            order: 2;
            @include variables.for-desktop-up {
                width: 97.5%;
                order: 3;
            }

            .categories {
                display: none;
            }

            .title {
                font-size: 1.875rem;
                line-height: 2.5rem;
                margin-bottom: 15px;
            }
        }
    }

    &-box,
    &-box--brands {
        flex: 0 1 50%;
        margin: 40px 15px 0px 15px;
        order: 1;
        @include variables.for-desktop-up {
            flex: 1 0 47%;
        }
        h3 {
            flex: 0 0 100%;
            margin-bottom: 15px;
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;
        margin: 0px -5px;
    }

    &-item {
        flex: 0 0 100%;
        padding: 5px;
        min-height: 126px;
        @include variables.for-mediumUp-only {
            flex: 0 0 50%;
        }

        &__content {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 20px;
            border: none;
            background-color: #f8f8f8;
            border-radius: 4px;
            height: 100%;
            position: relative;
            overflow: hidden;
            font-size: 16px;
            transition: all 0.1s;
            @include variables.bold-font;
            &:hover {
                text-decoration: underline;
                transition: all 0.1s;
            }
        }

        img {
            max-width: 116px;
            margin-left: auto;
            border: none;
        }
    }

    &-box--brands {
        order: 3;
        @include variables.for-desktop-up {
            flex: 1 0 47%;
        }
        .component-homeBlock-item {
            flex: 0 0 50%;
            @include variables.for-mediumUp-only {
                flex: 0 0 33.33%;
            }

            &__content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 20px 10px;
                border: 1px solid #e2e2e2;
                border-radius: 4px;
                background-color: #fff;

                svg {
                    max-width: 120px;
                    max-height: 40px;
                }

                &:hover {
                    transition: all 0.1s;
                    border: 1px solid;
                    background-color: #000;
                    svg,
                    path,
                    polyline,
                    rect,
                    text,
                    polygon {
                        fill: #fff;
                    }
                    
                }
            }

            &:last-child {
                flex: 0 0 100%;
                @include variables.for-mediumUp-only {
                    flex: 0 0 33.33%;
                }
                .component-homeBlock-item {
                    min-height: 116px;
                    &__content {
                        height: 100%;
                        background-color: #fff;
                        color: #000;
                        @include variables.bold-font;
                        &:hover {
                            border: 1px solid #e2e2e2;
                        }
                    }
                }
            }
        }
    }
}
