@use 'variables';

.legacy-form-input {
    flex: 0 0 100%;
    margin-bottom: 20px;
    @include variables.for-tablet-landscape-up {
        flex: 0 0 50%;
        padding: 0px 5px 30px 5px;
        margin: 0px -5px;
    }

    label {
        display: block;
        font-size: 0.875rem;
        margin-bottom: 13px;
        @include variables.bold-font;
    }

    input {
        border-radius: 4px;
        border: 1px solid #e2e2e2;
        height: 50px;
        padding-left: 15px;
        width: 100%;
        -webkit-appearance: none;
    }
}
