@use 'variables';

.component-login {
    padding-bottom: 20px;
    border-bottom: 1px solid #e2e2e2;
    &__form {
        padding-top: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        &__buttons {
            display: flex;
            align-items: center;
            flex: 0 0 100%;
            padding-bottom: 20px;
        }

        &__button {
            display: flex;
            p {
                cursor: pointer;
                background: #ffffff;
                border-radius: 4px;
                border: 1px solid #e2e2e2;
                font-size: 0.875rem;
                padding: 14px 20px;
                @include variables.bold-font;
                &:hover {
                    background: #00A053;
                    border: 1px solid #00A053;
                    color: #ffffff;
                }
            }
        }

        &__title {
            padding-bottom: 30px;
            flex: 0 0 100%;
        }
    }

    &__reset {
        margin-left: auto;
        text-decoration: underline;
        cursor: pointer;
    }
}
