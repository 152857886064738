@use 'variables';

.modal-global {
    background: #fff;
    position: absolute;
    z-index: 100;
    top: 0;
    margin-top: 36px;
    width: 90%;
    &.center {
        left: 50%;
        border-radius: 10px;
        max-height: 610px;
        max-width: fit-content;
        min-width: 280px;
        top: 44%;
        transform: translate(-50%, -50%);
    }
    &.drawer {
        height: 100vh;
        max-width: 510px;
        right: 0;
        z-index: 9999;
        top: 0;
        width: 100%;
        .modal-content {
            @include variables.for-tablet-portrait-up {
                padding: 2.5rem;
            }
        }
    }
    .top {
        align-items: center;
        display: flex;
        font-size: 1rem;
        justify-content: space-between;
        margin-bottom: 25px;
        @include variables.bold-font;
        .close-sidebar {
            align-items: center;
            cursor: pointer;
            display: flex;
            img {
                background: #000000;
                border-radius: 4px;
                height: 40px;
                margin-left: 12px;
                padding: 12px;
                width: 40px;
            }
        }
    }
    .modal-content {
        padding: 1rem;
    }
}
